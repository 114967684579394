import { useLazyQuery, useMutation } from '@apollo/client';
import { Check, Clock, Trash } from '@phosphor-icons/react';
import { Alert, Col, Flex, Form, Input, Row, Spin, Tooltip } from 'antd';
import { useForm } from 'antd/es/form/Form';
import Paragraph from 'antd/es/typography/Paragraph';
import { lowerCase, startCase } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import {
  AddCustomDomainInput,
  DomainVerificationRecordStatus,
  VerifyCustomDomainType,
  WorkspaceCustomDomain,
} from '../../__generated__/graphql';
import { AppContext } from '../../AppContext';
import { COMING_SOON_TEXT } from '../../common/constants';
import {
  formValidation,
  handleGraphQlError,
  handleGraphQlSuccess,
  hasFeatureAccess,
} from '../../common/utils';
import DomainRecordsModal from '../../components/common/DomainRecordsModal';
import PremiumLogo from '../../components/common/PremiumLogo';
import RemoveDomainModal from '../../components/common/RemoveDomainModal';
import SubscribeModal from '../../components/common/SubscribeModal';
import CommonButton from '../../components/primitives/CommonButton';
import useFeatureAccess from '../../hooks/useFeatureAccess';
import useRouter from '../../hooks/useRouter';
import { AppContextType } from '../../types/appContext.type';
import { FeatureKeys } from '../profile/profile.types';
import {
  ADD_CUSTOM_DOMAIN,
  REMOVE_DOMAIN,
  VERIFY_DOMAIN,
} from './graphql/mutations';
import { GET_DOMAIN_DETAILS } from './graphql/queries';

export default function DomainSettings({ isOwner }: { isOwner: boolean }) {
  const {
    checkFeatureAccess,
    isSubscriptionModalVisible,
    handleUpgradeSuccess,
    closeSubscriptionModal,
  } = useFeatureAccess();
  const { params } = useRouter();
  const [customDomainForm] = useForm();
  const [domainInfo, setDomainInfo] = useState<WorkspaceCustomDomain>();
  const [recordModal, setRecordModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const {
    state: { currentUser },
  } = useContext(AppContext) as AppContextType;

  const [fetchDomains, { loading }] = useLazyQuery(GET_DOMAIN_DETAILS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res.domain?.data) {
        setDomainInfo(res.domain?.data);
        customDomainForm.setFields([
          { name: 'customDomain', value: res.domain.data.customDomain },
        ]);
      }
    },
    onError: () => {},
  });

  const [verifyDomainMutate, { loading: verifyLoading }] = useMutation(
    VERIFY_DOMAIN,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        if (res.verifyCustomDomain?.data) {
          setDomainInfo(res.verifyCustomDomain?.data);
        }
      },
      onError: (err) => {
        handleGraphQlError(err?.message);
      },
    },
  );

  const [removeDomainMutate, { loading: deleteLoading }] = useMutation(
    REMOVE_DOMAIN,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        customDomainForm.resetFields();
        setDomainInfo({});
        setDeleteModal(false);
        handleGraphQlSuccess(res.removeCustomDomain?.message);
      },
      onError: (err) => {
        handleGraphQlError(err?.message);
      },
    },
  );

  const [addCustomDomainMutate, { loading: domainLoading }] = useMutation(
    ADD_CUSTOM_DOMAIN,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        if (res.addCustomDomain?.data) {
          setDomainInfo(res.addCustomDomain.data);
        }
      },
      onError: (err) => {
        customDomainForm.setFields([
          {
            name: ['customDomain'],
            errors: [err?.message],
          },
        ]);
      },
    },
  );

  const hasCustomDomainAccess = hasFeatureAccess(
    currentUser?.subscriptionPlan?.features,
    FeatureKeys.CUSTOM_DOMAIN,
  );

  useEffect(() => {
    customDomainForm.resetFields();
    fetchDomains({
      context: {
        headers: {
          'x-workspace-id': params?.id,
        },
      },
    });
  }, [params]);

  const handleCustomDomainFinish = (fieldsValue: AddCustomDomainInput) => {
    const { customDomain } = fieldsValue;
    addCustomDomainMutate({
      context: {
        headers: {
          'x-workspace-id': params?.id,
        },
      },
      variables: {
        data: {
          customDomain,
        },
      },
    });
  };

  const handleVerifyDomain = (type: VerifyCustomDomainType) => {
    verifyDomainMutate({
      context: {
        headers: {
          'x-workspace-id': params?.id,
        },
      },
      variables: {
        data: {
          type,
        },
      },
    });
  };

  const handleRemoveDomain = (type: VerifyCustomDomainType) => {
    removeDomainMutate({
      context: {
        headers: {
          'x-workspace-id': params?.id,
        },
      },
      variables: {
        data: {
          type,
        },
      },
    });
  };

  return (
    <Spin spinning={loading}>
      <Form
        autoComplete="off"
        disabled={!isOwner}
        onFinish={handleCustomDomainFinish}
        form={customDomainForm}
        className="custom-domain-form"
      >
        <Flex vertical gap={2}>
          <Flex align="center" gap={6}>
            <Paragraph className="mb-0 text-base text-content-primary medium">
              Custom domain
            </Paragraph>
            {!hasCustomDomainAccess && <PremiumLogo isSubscriptionModal />}
          </Flex>
          <Flex align="center" className="mb-16" gap={4}>
            <Paragraph className="mb-0 text-sm text-content-tertiary">
              Use your own domain for a unique web address.
            </Paragraph>
            {domainInfo?.customDomainStatus ===
              DomainVerificationRecordStatus.VerificationPending && (
              <Alert
                message={startCase(
                  lowerCase(DomainVerificationRecordStatus.VerificationPending),
                )}
                type="warning"
                showIcon
                icon={
                  <Clock
                    weight="fill"
                    size={14}
                    color="var(--caution-on-surface)"
                  />
                }
                className="small"
              />
            )}
          </Flex>
        </Flex>

        <Row gutter={[8, 8]} justify="space-between">
          <Col span={!domainInfo?.customDomain ? 22 : 14}>
            <Form.Item
              name="customDomain"
              rules={[formValidation.required, formValidation.domain]}
            >
              <Input
                placeholder="e.g. john.com"
                disabled={!!domainInfo?.customDomain}
                onFocus={() => {
                  checkFeatureAccess(FeatureKeys.CUSTOM_DOMAIN, () => {
                    // do nothing
                  });
                }}
              />
            </Form.Item>
          </Col>
          {!domainInfo?.customDomain ? (
            <Col span={2}>
              <CommonButton
                type="text"
                shape="circle"
                icon={<Check size={20} color="var(--content-primary)" />}
                loading={domainLoading}
                htmlType="submit"
              />
            </Col>
          ) : (
            <Col span={10}>
              <Flex gap={8} justify="space-between">
                <CommonButton
                  onClick={() => {
                    setRecordModal(true);
                  }}
                >
                  View all records
                </CommonButton>
                <CommonButton
                  type="text"
                  shape="circle"
                  icon={<Trash color="var(--danger-on-surface)" size={20} />}
                  onClick={() => {
                    setDeleteModal(true);
                  }}
                />
              </Flex>
            </Col>
          )}
        </Row>
      </Form>
      <Tooltip title={COMING_SOON_TEXT}>
        <Form autoComplete="off" disabled={!isOwner || true}>
          <Flex vertical gap={8}>
            <Flex vertical gap={2}>
              <Paragraph className="mb-0 text-base text-content-primary medium">
                Custom Subdomain
              </Paragraph>
              <Paragraph className="mb-0 text-sm text-content-tertiary">
                Only one per workspace and non-editable once made.
              </Paragraph>
            </Flex>
            <Row wrap gutter={[8, 8]} justify="space-between">
              <Col span={14}>
                <Form.Item
                  name="subDomain"
                  rules={[formValidation.required, formValidation.domain]}
                >
                  <Input placeholder="Enter subdomain name" />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item>
                  <Input
                    placeholder="Type here..."
                    value=".zinq.ai/"
                    disabled
                  />
                </Form.Item>
              </Col>

              <Col span={2}>
                <CommonButton
                  type="text"
                  shape="circle"
                  icon={<Check size={20} color="var(--content-primary)" />}
                  htmlType="submit"
                />
              </Col>
            </Row>
          </Flex>
        </Form>
      </Tooltip>
      {isSubscriptionModalVisible && (
        <SubscribeModal
          visible={isSubscriptionModalVisible}
          onCancel={closeSubscriptionModal}
          onUpgrade={handleUpgradeSuccess}
        />
      )}
      {recordModal && domainInfo && (
        <DomainRecordsModal
          isVisible={recordModal}
          onCancel={() => setRecordModal(false)}
          domainInfo={domainInfo}
          loading={verifyLoading}
          onVerifyClick={() => {
            handleVerifyDomain(VerifyCustomDomainType.CustomDomain);
          }}
        />
      )}
      {deleteModal && domainInfo && (
        <RemoveDomainModal
          open={deleteModal}
          domainInfo={domainInfo}
          title="Remove Domain"
          okText="Remove domain"
          content="You're about to remove your custom domain."
          onClose={() => {
            setDeleteModal(false);
          }}
          onOk={() => {
            handleRemoveDomain(VerifyCustomDomainType.CustomDomain);
          }}
          cancelButtonProps={{
            disabled: deleteLoading,
          }}
          okButtonProps={{
            danger: true,
            loading: deleteLoading,
            type: 'text',
            icon: (
              <Trash
                size={16}
                color="var(--danger-on-surface)"
                weight="regular"
              />
            ),
          }}
        />
      )}
    </Spin>
  );
}
