('use Client');
import { Flex, Modal } from 'antd';
import Title from 'antd/es/typography/Title';
import { useEffect, useState } from 'react';
import { BrandProgressBarSvg } from '../../assets/icons';
import { MODAL_SIZE } from '../../common/constants';

type PurchaseSuccessModalProps = {
  isVisible: boolean;
};

const PurchaseSuccessModal: React.FC<PurchaseSuccessModalProps> = ({
  isVisible,
}) => {
  const texts = [
    'Gathering your features',
    'Activating features',
    'Preparing your dashboard',
    'Configuring tools',
    'Finalizing upgrades',
    'Almost ready...',
  ];
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [texts.length]);

  return (
    <>
      <Modal
        open={isVisible}
        width={MODAL_SIZE.medium}
        footer={null}
        closable={false}
        className="purchase-success-modal"
        centered
      >
        <Flex
          className="upgrade-success-content"
          gap={16}
          align="center"
          justify="center"
          vertical
        >
          {texts.map((text, index) => (
            <Title
              key={index}
              level={2}
              className={`gradient-text text-lg semi-bold font-secondary animate-text ${activeIndex === index ? 'visible' : 'hidden'}`}
            >
              {text}
            </Title>
          ))}
          <BrandProgressBarSvg />
        </Flex>
      </Modal>
    </>
  );
};

export default PurchaseSuccessModal;
