import { UploadFile } from 'antd';
import { Rule } from 'antd/es/form';
import { RcFile } from 'antd/es/upload';
import { Dayjs } from 'dayjs';
import {
  AiTone,
  FormSubmissionType,
  InitiateVoiceFormSubmissionInput,
} from '../../__generated__/graphql';
import { ID } from '../../types/common.type';

export enum FormFieldType {
  SHORT_TEXT = 'SHORT_TEXT',
  LONG_TEXT = 'LONG_TEXT',
  TEXT = 'TEXT',
  TEXT_AREA = 'TEXT_AREA',
  PHONE_NUMBER = 'PHONE_NUMBER',
  NUMBER = 'NUMBER',
  EMAIL = 'EMAIL',
  SELECT = 'SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  TIME = 'TIME',
  DATE = 'DATE',
  CONTACT_INFO = 'CONTACT_INFO',
  LINEAR_SCALE = 'LINEAR_SCALE',
  ADDRESS = 'ADDRESS',
  FILE = 'FILE',
  RATE = 'RATE',
  PAYMENT = 'PAYMENT',
  CALENDAR = 'CALENDAR',
  DATE_RANGE = 'DATE_RANGE',
  DROPDOWN = 'DROPDOWN',
  PICTURE_CHOICE = 'PICTURE_CHOICE',
  WELCOME_PAGE = 'WELCOME_PAGE',
  END_PAGE = 'END_PAGE',
  NUMBER_TEXT = 'NUMBER_TEXT',
}

export type FormFieldProps = {
  name?: string | string[];
  label?: string;
  rules?: Rule[];
  apiErrors: Record<string, string>;
  type: FormFieldType;
  options?: { value: string | number; label: string }[];
  multiple?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputProps?: Record<string, any>; // dynamic inputProps can be of any type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formItemProps?: Record<string, any>; // dynamic formItemProps can be of any type
};

export enum FormFileType {
  // eslint-disable-next-line no-unused-vars
  DOCUMENT = 'DOCUMENT',
  // eslint-disable-next-line no-unused-vars
  AUDIO = 'AUDIO',
  // eslint-disable-next-line no-unused-vars
  VIDEO = 'VIDEO',
  // eslint-disable-next-line no-unused-vars
  IMAGE = 'IMAGE',
}

export enum FormTypeLabel {
  conversational = 'Default',
  chat = 'Chat',
  classic = 'Classic',
  voice = 'Phone',
}

export enum EmbedTypes {
  IFRAME = 'IFRAME',
  POPUP = 'POPUP',
  SLIDER = 'SLIDER',
  POPOVER = 'POPOVER',
  SIDE_TAB = 'SIDETAB',
}

export enum EmbedSliderPosition {
  Right = 'RIGHT',
  Left = 'LEFT',
}

export type EmbedButtonConfig = {
  text: string;
  fontSize: number;
  radius: number;
  backgroundColor: string;
  icon: string;
  iconColor: string;
};

export type EmbedConfigsType = {
  type: EmbedTypes;
  width?: {
    unit: 'px' | '%';
    value: number;
  };
  height?: {
    unit: 'px' | '%';
    value: number;
  };
  popupSize?: 50 | 70 | 100;
  position?: EmbedSliderPosition;
  isWelcomePage: boolean;
  button?: EmbedButtonConfig;
};

export enum FormPreviewType {
  desktop = 'Desktop',
  mobile = 'Mobile',
}

export enum PageLayoutType {
  // eslint-disable-next-line no-unused-vars
  PAGE_FILLED = 'PAGE_FILLED',
  // eslint-disable-next-line no-unused-vars
  PAGE_TOP_CENTER = 'PAGE_TOP_CENTER',
  // eslint-disable-next-line no-unused-vars
  PAGE_LEFT = 'PAGE_LEFT',
}
export enum FormPageType {
  WELCOME_PAGE = 'WELCOME_PAGE',
  END_PAGE = 'END_PAGE',
  REVIEW_PAGE = 'REVIEW_PAGE',
}

export type AdditionalConfigType = {
  showFooter: boolean;
  allowVoice: boolean;
  titleGap: number;
  showRequiredMark: boolean;
};

export enum FormElementType {
  PAGE = 'PAGE',
  SECTION = 'SECTION',
  FIELD = 'FIELD',
}
export type FormSchemaType = {
  type: FormElementType;
  page_type?: FormPageType;
  field_type?: FormFieldType;
  name?: string;
  answer_store_key: ID;
  aiResponse?: string;
  question_id?: string;
  title?: string;
  question_text?: string;
  question_description?: string;
  ai_guideline?: string;
  is_required?: boolean;
  is_display?: boolean;
  hidden?: boolean;
  placeholder?: string;
  options?: Record<string, string>[] | string[];
  validations?: Record<string, string | string[] | boolean | number | RegExp>;
  showAllTags?: boolean;
  body?: string;
  cta_name?: string;
  cta_link?: string;
  page_layout?: string;
  bg_image?: string;
  bg_image_url?: string;
  bg_file_name?: string | null;
  newItem?: boolean;
  is_user_key?: boolean;
  config?: {
    min_rating?: number;
    max_rating?: number;
    character_limit?: number;
    min_number?: number;
    max_number?: number;
    min_time?: string;
    max_time?: string;
    min_label?: string;
    max_label?: string;
    file_type?: FormFileType[];
    max_file_count?: number;
    max_file_size?: number;
    allow_past_date?: boolean;
    allow_future_date?: boolean;
    uiStyle?: string;
    regex?: RegExp | string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any; // Using any for dynamic values
  } | null;
  nested?: FormSchemaType[];
};

export type CustomFile = {
  percent?: number;
  status?: string;
} & UploadFile;

export type InfoType = {
  name: string | string[];
  label?: string;
};

export enum FormCreationTabs {
  FORM = 'FORM',
  DESIGN = 'DESIGN',
  SETTINGS = 'SETTINGS',
  TEMPLATE_SETTING = 'TEMPLATE',
}

export enum FormMode {
  // eslint-disable-next-line no-unused-vars
  LIGHT = 'LIGHT',
  // eslint-disable-next-line no-unused-vars
  DARK = 'DARK',
  // eslint-disable-next-line no-unused-vars
  AUTO = 'AUTO',
}

export type InputChangeTypes = {
  id: ID;
  path: string[];
  value:
    | Array<string | number | boolean>
    | Array<Array<string | number | boolean>>;
};

export type InputFormDesignTypes = {
  key: number | string;
  value: string | string[] | boolean | number | string | Dayjs;
};

export type NestedInputChangeTypes = {
  key: number | string;
  field: string;
  value: string | string[] | boolean | number;
  nestedId: number | string;
};

export enum FormThemeType {
  SOLID = 'SOLID',
  GRADIENT = 'GRADIENT',
  PATTERN = 'PATTERN',
}

export type Palette = {
  bgImage: string | null;
  bgPrimary: string;
  textColor: string;
  fontFamily: string;
  bgSecondary: string;
  gradientDegree: number;
  errorColor: string;
  requiredColor: string;
};

export type ColorPalette = {
  dark: Palette;
  light: Palette;
};

export type CustomUploadRequestOptions = {
  file: RcFile;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSuccess: (response: any, file: RcFile) => void;
  onError: (error: Error) => void;
  onProgress: (event: { percent: number }) => void;
};

export type CustomCharacterProps = {
  index: number;
  value: number;
};

export type InitiateVoiceFormSubmissionInputType =
  InitiateVoiceFormSubmissionInput & {
    tone?: AiTone;
    phone?: string;
    prefix?: string;
  };

export type FormOption = {
  value: FormSubmissionType;
  label: FormTypeLabel | JSX.Element;
  disabled?: boolean;
  icon: JSX.Element;
};

export enum SubscriptionPlans {
  ALPHA_FEATURE = 'ALPHA_FEATURE',
}

export enum SocialShareType {
  FACEBOOK = 'FACEBOOK',
  TWITTER = 'TWITTER',
  LINKEDIN = 'LINKEDIN',
}

export enum AIGenerateFormError {
  INVALID_PROMPT = 'INVALID_PROMPT',
}
