import { FormFieldType } from '../modules/form/form.types';

export type ChildrenPropType = {
  children: React.ReactNode;
};

export type UserData = {
  firstName?: string;
  lastName?: string;
  roles?: string[];
};

export type ChildrenType = {
  children?: React.ReactNode;
};

export type ID = string | number;

export type MetaTypes = Record<string, string>;

export enum Permissions {
  READ = 'read',
  WRITE = 'write',
  OWNER = 'owner',
}

export type FormControlType = {
  isPremium?: boolean;
  label: string;
  value: FormFieldType;
  isComingSoon?: boolean;
};

export enum ZinqSubDomain {
  FORM = 'form',
}

export enum FeatureName {
  Invite = 'Invite member',
  EndPage = 'End page',
  WelcomePage = 'Welcome page',
  EndPageCta = 'End page CTA',
  AdvancedField = 'Advanced field',
  RemoveBranding = 'Remove branding',
  BrandLogo = 'Brand logo',
  MultiFormLink = 'Multi form link',
  FormCreation = 'Form creation',
  PartialForm = 'Partial form',
  PremiumPlan = 'Premium plan',
  CustomDomain = 'Custom domain',
  UtmTracking = 'UTM tracking',
  FormScheduling = 'Form scheduling',
  Storage = 'Storage',
  TagTracking = 'Tag tracking',
  AddQuestion = 'Add question',
  PastDate = 'Past date',
  FutureDate = 'Future date',
  SummaryOnceADay = 'Summary once a day',
  summaryOnSubmission = 'Summary on a submission',
}
