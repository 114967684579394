import { gql } from '../../../__generated__';

export const REGISTER_USER = gql(`
  mutation SignUp($data: SignUpInputData!) {
  signUp(data: $data) {
    message
  }
}`);

export const LOGIN_USER = gql(`
  mutation LoginUser($data: LoginUserInput!) {
  loginUser(data: $data) {
    accessToken
    user {
      email
      phoneNumber
      subscriptionPlan {
        features
      }
    }
    refreshToken
  }
}`);

export const RESEND_VERIFICATION_EMAIL = gql(`
  mutation ResendVerificationEmail($data: ResendVerificationEmailData!) {
  resendVerificationEmail(data: $data) {
    message
  }
}`);

export const VERIFY_EMAIL = gql(`
  mutation VerifyEmail($data: VerifyEmailInput) {
  verifyEmail(data: $data) {
    message
    user {
        email
        name
        phoneNumber
        countryCode
        profileImage
        countryCode
        isNewUser
        currentWorkspace {
          id
          uuid
          name
          workspaceMembers {
            id
            role
          }
        }
        subscriptionPlan {
          features
        }
      }
    accessToken
    refreshToken
  }
}`);

export const RESET_PASSWORD = gql(`
 mutation ResetPassword($data: ResetPasswordInput!) {
  resetPassword(data: $data) {
    message
  }
}`);

export const FORGOT_PASSWORD = gql(`
  mutation ForgotPassword($data: ForgotPasswordInput!) {
  forgotPassword(data: $data) {
    message
  }
}`);

export const LOGOUT_USER = gql(`
  mutation Logout {
  logout {
    message
  }
}`);

export const FIREBASE_LOGIN = gql(`
  mutation FirebaseLogin($data: FirebaseLoginDataInput) {
  firebaseLogin(data: $data) {
    user {
      email
      name
      phoneNumber
      countryCode
      profileImage
      countryCode
      isNewUser
      currentWorkspace {
        id
        uuid
        name
        workspaceMembers {
          id
          role
        }
      }
      subscriptionPlan {
        features
      }
    }
    accessToken
    refreshToken
  }
}`);

export const USER_SIGNUP = gql(`
  mutation SignUp($data: SignUpInputData!) {
  signUp(data: $data) {
    message
  }
}`);

export const USER_LOGIN = gql(`
 mutation LoginEmailUser($data: LoginUserInput!) {
  loginUser(data: $data) {
    accessToken
    refreshToken
    user {
      email
      name
      phoneNumber
      countryCode
      profileImage
      countryCode
      isNewUser
      emailVerifiedAt
      currentWorkspace {
        id
        uuid
        name
        workspaceMembers {
          id
          role
        }
      }
      subscriptionPlan {
        features
      }
    }
  }
}`);

export const REFRESH_TOKEN = gql(`
  mutation RefreshToken($data: RefreshTokenInput!) {
  refreshToken(data: $data) {
    accessToken
    user {
      email
      name
      phoneNumber
      countryCode
      subscriptionPlan {
        features
      }
    }
  }
}`);

export const UPDATE_USER_PROFILE = gql(`
 mutation UpdateUserProfile($data: UserProfileData!) {
  updateUserProfile(data: $data) {
    message
    user {
      profileImage
    }
  }
}`);
