import { Flex } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import PremiumLogo from '../../../../components/common/PremiumLogo';
import { FeatureName } from '../../../../types/common.type';

type AddMenuItemType = {
  title: string;
  description: string;
  icon: React.ReactNode;
  isPremium?: boolean;
  featureName: FeatureName;
};

export default function AddMenuItem({
  title,
  description,
  icon,
  isPremium,
  featureName,
}: AddMenuItemType) {
  return (
    <Flex gap={8} align="center">
      <Flex>{icon}</Flex>
      <Flex vertical gap={4}>
        <Flex gap={4} align="center">
          <Paragraph className="mb-0">{title}</Paragraph>
          {isPremium && <PremiumLogo featureName={featureName} />}
        </Flex>
        <Paragraph className="mb-0 text-meta text-content-secondary text-description">
          {description}
        </Paragraph>
      </Flex>
    </Flex>
  );
}
