import { gql } from '../../../__generated__';

export const GET_USERS_WORKSPACES = gql(`
  query Workspaces($filter: ListWorkspaceFilter, $sort: [ListWorkspaceSort!]) {
  workspaces(filter: $filter, sort: $sort) {
    allowWorkspaceCreation
    count
    data {
      id
      name
      uuid
      workspaceMembers {
        id
        role
      }
    }
  }}`);

export const GET_WORKSPACE_DETAILS = gql(`
  query Workspace($where: WorkspaceUniqueUuidInput!) {
    workspace(where: $where) {
      data {
        name
        uuid
        default
        workspaceMembers {
          role
        }
      }
    }
}`);

export const GET_WORKSPACE_MEMBERS = gql(`
  query WorkspaceMembers($filter: ListWorkspaceMembersFilter, $sort: [ListWorkspaceMembersSort!], $where: WorkspaceUniqueUuidInput!) {
    workspaceMembers(filter: $filter, sort: $sort, where: $where) {
      count
      totalMembers
      subscriptionPlan {
        features
      }
      data {
        member {
          email
          name
        }
        role
        status
        email
        id
      }
    }
}`);

export const GET_WORKSPACE_CREDIT = gql(`
  query getWorkspaceCredit {
   currentUser {
    ltdWorkspaceBalance
    subscriptionPlan {
      label
     }
   }
 }`);

export const GET_DOMAIN_DETAILS = gql(`
  query GetDomainDetails {
    domain {
    data {
      customDomainStatus
      customDomain
      customDomainVerificationRecords {
        name
        type
        value
        status
      }
      id
    }
  }
}`);
