import { useLazyQuery, useMutation } from '@apollo/client';
import { Alert, Flex, Skeleton } from 'antd';
import Link from 'antd/es/typography/Link';
import Paragraph from 'antd/es/typography/Paragraph';
import { useContext, useEffect, useState } from 'react';
import {
  SubscriptionPlanLabel,
  User,
  WorkspacesResponse,
} from '../../__generated__/graphql';
import { AppContext } from '../../AppContext';
import { initialWorkspaceListFilter } from '../../common/constants';
import { handleGraphQlError, handleGraphQlSuccess } from '../../common/utils';
import CommonDeleteModal from '../../components/common/CommonDeleteModal';
import { AppActionType, AppContextType } from '../../types/appContext.type';
import { USE_LTD_PLAN } from './graphql/mutations';
import { GET_USERS_WORKSPACES, GET_WORKSPACE_CREDIT } from './graphql/queries';

export default function WorkspaceCredits({
  uuid,
  isOwner,
  switchWorkspace = false,
}: {
  uuid: string;
  isOwner: boolean;
  switchWorkspace?: boolean;
}) {
  const [details, setDetails] = useState<User>({});
  const [fetchLoading, setFetchLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { dispatch, updateCurrentUser } = useContext(
    AppContext,
  ) as AppContextType;

  const [getWorkspaceCredit, { loading }] = useLazyQuery(GET_WORKSPACE_CREDIT, {
    fetchPolicy: 'no-cache',
    onError: () => {},
    onCompleted: (res) => {
      if (res.currentUser) {
        setDetails(res.currentUser);
      }
    },
  });

  const [fetchWorkspaces] = useLazyQuery(GET_USERS_WORKSPACES, {
    fetchPolicy: 'network-only',
    onError: () => {},
    onCompleted: (res) => {
      dispatch({
        type: AppActionType.userWorkspaces,
        data: res.workspaces as WorkspacesResponse,
      });
    },
  });

  const [useLtdPlanMutate, { loading: useLoading }] = useMutation(
    USE_LTD_PLAN,
    {
      onCompleted: (res) => {
        if (res.useLtdPlan) {
          handleGraphQlSuccess(res.useLtdPlan.message);
          if (switchWorkspace) {
            setFetchLoading(true);
            updateCurrentUser(uuid, async () => {
              setFetchLoading(false);
              getWorkspaceCredit({
                context: {
                  headers: {
                    'x-workspace-id': uuid,
                  },
                },
              });
              await fetchWorkspaces({
                variables: initialWorkspaceListFilter,
              });
            });
          } else {
            getWorkspaceCredit({
              context: {
                headers: {
                  'x-workspace-id': uuid,
                },
              },
            });
          }
        }
      },
      onError: (res) => {
        handleGraphQlError(res?.message);
      },
    },
  );

  useEffect(() => {
    if (isOwner) {
      getWorkspaceCredit({
        context: {
          headers: {
            'x-workspace-id': uuid,
          },
        },
      });
    }
  }, [uuid, isOwner]);

  const handleApplyButton = () => {
    useLtdPlanMutate({
      context: {
        headers: {
          'x-workspace-id': uuid,
        },
      },
    });
  };

  if (loading) {
    return (
      <div className="mb-24">
        <Skeleton.Input active />
      </div>
    );
  }

  if (
    details?.subscriptionPlan?.label !== SubscriptionPlanLabel.Free ||
    !isOwner ||
    Number(details.ltdWorkspaceBalance) === 0
  ) {
    return null;
  }

  return (
    <>
      <Alert
        type="info"
        message={
          <Flex gap={8}>
            <Paragraph className="mb-0 text-content-primary medium">
              You have {details.ltdWorkspaceBalance} lifetime deal credits.
            </Paragraph>
            {useLoading || fetchLoading ? (
              <Link underline disabled>
                Applying...
              </Link>
            ) : (
              <Link
                underline
                onClick={() => {
                  setIsOpenModal(true);
                }}
              >
                Apply to this workspace
              </Link>
            )}
          </Flex>
        }
        className="mb-24"
      />
      {isOpenModal && (
        <CommonDeleteModal
          open={isOpenModal}
          title="Confirm workspace upgrade"
          content="Converting this workspace to a lifetime deal is permanent and irreversible."
          onClose={() => {
            setIsOpenModal(false);
          }}
          onOk={handleApplyButton}
          okText="Apply"
          cancelButtonProps={{
            disabled: loading,
          }}
          okButtonProps={{
            loading: loading,
            type: 'primary',
          }}
        />
      )}
    </>
  );
}
