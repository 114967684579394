import { Flex } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';

export default function LifetimePurchaseBanner() {
  return (
    <div className="lifetime-purchase-banner">
      <Flex
        className="lifetime-purchase-content"
        gap={16}
        align="center"
        justify="center"
        vertical
      >
        <Title className="gradient-text text-lg semi-bold font-secondary">
          Lifetime Workspace
        </Title>
        <Paragraph className="text-content-inverse-secondary medium text-center mb-0">
          This workspace is part of your lifetime deal. No additional plans are
          needed—your benefits are set for the long run.
        </Paragraph>
      </Flex>
    </div>
  );
}
