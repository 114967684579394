import { CaretDown } from '@phosphor-icons/react';
import { ConfigProvider, Form, InputNumber, Select } from 'antd';
import { NamePath } from 'antd/es/form/interface';

export default function DimensionInput({
  name,
  label,
  hidden,
}: {
  name: NamePath;
  label: string;
  hidden: boolean;
}) {
  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            selectorBg: 'var(--neutrals)',
            controlHeight: 28,
            borderRadius: 10,
          },
        },
      }}
    >
      <div className="dimension-input-wrapper">
        <Form.Item label={label} labelCol={{ span: 24 }} hidden={hidden}>
          <Form.Item name={[...name, 'value']} hidden={hidden}>
            <InputNumber
              className="w-full"
              placeholder={label}
              controls={false}
              min={0}
            />
          </Form.Item>
          <Form.Item
            name={[...name, 'unit']}
            className="unit-selector"
            hidden={hidden}
          >
            <Select
              popupMatchSelectWidth={false}
              suffixIcon={
                <CaretDown size={16} color="var(--content-secondary)" />
              }
            >
              <Select.Option value="px">px</Select.Option>
              <Select.Option value="%">%</Select.Option>
            </Select>
          </Form.Item>
        </Form.Item>
      </div>
    </ConfigProvider>
  );
}
