import type { TourProps } from 'antd';
import { Tour } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import { COMPANY_INFO } from '../../../common/constants';
import { setCookie } from '../../../common/utils';
import { AppActionType, AppContextType } from '../../../types/appContext.type';
import CustomIndicator from './CustomIndicator';

type AppMenuTourProps = {
  refs: React.RefObject<HTMLElement>[];
  open: boolean;
  onOpenChange: (open: boolean) => void;
};

const AppMenuTour: React.FC<AppMenuTourProps> = ({
  refs,
  open,
  onOpenChange,
}) => {
  const { dispatch } = useContext(AppContext) as AppContextType;
  const handleClose = () => {
    onOpenChange(false);
    setCookie('app-menu-tour', true, COMPANY_INFO.appMenuTourCookieExpiry);
    dispatch({
      type: AppActionType.triggerTour,
      data: { editor: true, preview: false },
    });
  };
  const steps: TourProps['steps'] = [
    {
      title: '',
      description: `Where you'll build your form. Add questions, customize the design, and bring your vision to life.`,
      target: () => refs[0].current as HTMLElement,
      onClose: handleClose,
    },
    {
      title: '',
      description: `Automate your workflow! Set up notifications, integrations, and scheduling to streamline your process.`,
      target: () => refs[1].current as HTMLElement,
      onClose: handleClose,
    },
    {
      title: '',
      description: `Ready to share your form? Find shareable links, embed codes, and more in the Share tab.`,
      target: () => refs[2].current as HTMLElement,
      onClose: handleClose,
    },
    {
      title: '',
      description: `Track responses, analyse data, and gain valuable insights in the Results tab.`,
      target: () => refs[3].current as HTMLElement,
      onClose: handleClose,
    },
    {
      title: '',
      description: 'Experience your own ZINQ form!',
      target: () => refs[4].current as HTMLElement,
      onClose: handleClose,
    },
    {
      title: '',
      description:
        'Once done, hit Publish to make it live and start collecting responses.',
      target: () => refs[5].current as HTMLElement,
      onClose: handleClose,
      onFinish: handleClose,
    },
  ];

  return (
    <Tour
      open={open}
      onClose={() => onOpenChange(false)}
      steps={steps}
      gap={{
        radius: 8,
      }}
      indicatorsRender={(current, total) => (
        <CustomIndicator current={current} total={total} />
      )}
      disabledInteraction
    />
  );
};

export default AppMenuTour;
