import { gql } from '../../../__generated__';

export const CREATE_USER_WORKSPACE = gql(`
mutation CreateWorkspace($data: CreateWorkspaceInput!) {
  createWorkspace(data: $data) {
    message
    data {
      id
      uuid
      name
    }
  }
}`);

export const UPDATE_WORKSPACE_SETTINGS = gql(`
mutation UpdateWorkspace($data: UpdateWorkspaceInput!, $where: WorkspaceUniqueUuidInput!) {
  updateWorkspace(data: $data, where: $where) {
    message
  }
}`);

export const DELETE_USER_WORKSPACE = gql(`
  mutation DeleteWorkspace($where: WorkspaceUniqueUuidInput!) {
  deleteWorkspace(where: $where) {
    message
    data {
      defaultWorkspaceId
    }
  }
}`);

export const INVITE_USER_WORKSPACE = gql(`
  mutation InviteUserInWorkspace($data: InviteWorkspaceMembersInput!, $where: WorkspaceUniqueUuidInput!) {
  inviteUserInWorkspace(data: $data, where: $where) {
    message
  }
}`);

export const DELETE_INVITE_MEMBER = gql(`
  mutation DeleteMember($where: WorkspaceMemberUniqueInput!) {
  deleteMember(where: $where) {
    message
  }
}`);

export const UPDATE_MEMBER_ROLE = gql(`
 mutation UpdateMemberRole($where: WorkspaceMemberUniqueInput!, $data: UpdateMemberRoleInput!) {
  updateMemberRole(where: $where, data: $data) {
    message
  }
}`);

export const RESEND_MEMBER_INVITE = gql(`
 mutation ResendInvitation($data: ResendInvitationInput!) {
  resendInvitation(data: $data) {
    message
  }
}`);

export const ACCEPT_WORKSPACE_INVITE = gql(`
 mutation AcceptInvitation($data: AcceptInvitationInput!) {
  acceptInvitation(data: $data) {
    message
    data {
      uuid
    }
  }
}`);

export const USE_LTD_PLAN = gql(`
  mutation UseLtdPlan {
  useLtdPlan {
    message
  }
}`);

export const ADD_CUSTOM_DOMAIN = gql(`
mutation AddCustomDomain($data: AddCustomDomainInput!) {
  addCustomDomain(data: $data) {
    data {
      customDomain
      customDomainStatus
      customDomainVerificationRecords {
        name
        type
        value
        status
      }
      id
    }
    message
  }
}`);

export const VERIFY_DOMAIN = gql(`
  mutation VerifyCustomDomain($data: VerifyCustomDomainInput!) {
  verifyCustomDomain(data: $data) {
    data {
      customDomain
      customDomainStatus
      customDomainVerificationRecords {
        name
        type
        value
        status
      }
      id
    }
  }
}`);

export const REMOVE_DOMAIN = gql(`
  mutation RemoveCustomDomain($data: RemoveDomainInput!) {
  removeCustomDomain(data: $data) {
    message
  }
}`);
