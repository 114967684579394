import { Warning } from '@phosphor-icons/react';
import { Flex, Modal, ModalProps } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import React, { useContext } from 'react';
import { AppContext } from '../../AppContext';
import { MODAL_SIZE } from '../../common/constants';
import { AppContextType } from '../../types/appContext.type';
import CommonButton from '../primitives/CommonButton';

type CommonCancelAlertModalProps = {
  isVisible: boolean;
  onCancel: () => void;
  onConfirm: (workspaceId: string) => void;
};

const CommonCancelAlertModal: React.FC<
  CommonCancelAlertModalProps & ModalProps
> = ({ isVisible, onCancel, onConfirm, okButtonProps, ...rest }) => {
  const {
    state: { workspaceDetails },
  } = useContext(AppContext) as AppContextType;
  return (
    <Modal
      open={isVisible}
      onCancel={onCancel}
      className="common-key-modal"
      footer={null}
      width={MODAL_SIZE.medium}
      {...rest}
    >
      <Flex vertical align="center" gap={16}>
        <Warning size={48} weight="fill" color="var(--caution-on-surface)" />
        <Flex vertical>
          <Title
            level={4}
            className="text-center text-neutrals-9 font-secondary"
          >
            Confirm Cancellation
          </Title>
          <Flex vertical align="center" gap={16}>
            <Paragraph className="text-sm text-content-secondary mb-0 text-center">
              You're about to cancel your subscription. This will end premium
              access and associated benefits.
            </Paragraph>
            <Flex align="center" gap={8}>
              <CommonButton
                key="confirm"
                type="text"
                onClick={onCancel}
                disabled={!!okButtonProps?.loading}
              >
                Keep Subscription
              </CommonButton>
              <CommonButton
                key="confirm"
                type="text"
                className="text-danger-on-surface"
                onClick={() => {
                  onConfirm(workspaceDetails?.data?.[0]?.uuid || '');
                }}
                loading={!!okButtonProps?.loading}
              >
                Confirm Cancellation
              </CommonButton>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default CommonCancelAlertModal;
