import { useMutation } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import { ROUTES, WORKSPACE_VERIFY_ID } from '../../common/constants';
import { handleGraphQlSuccess } from '../../common/utils';
import ScreenLayout from '../../components/layouts/ScreenLayout';
import useRouter from '../../hooks/useRouter';
import { AppContextType } from '../../types/appContext.type';
import FormLoading from '../form/editor/utils/FormLoading';
import { ACCEPT_WORKSPACE_INVITE } from './graphql/mutations';

export default function VerifyWorkspaceInvite() {
  const { navigate, params } = useRouter();
  const invitationId = params?.workspaceId;
  const [isLoading, setIsLoading] = useState(false);
  const { updateCurrentUser } = useContext(AppContext) as AppContextType;
  const [acceptWorkspaceInvite, { loading }] = useMutation(
    ACCEPT_WORKSPACE_INVITE,
    {
      onError: () => {},
    },
  );

  useEffect(() => {
    if (invitationId) {
      acceptWorkspaceInvite({
        variables: {
          data: { invitationId },
        },
        onCompleted: (res) => {
          const ownerWorkspace = res.acceptInvitation?.data?.uuid;
          localStorage.removeItem(WORKSPACE_VERIFY_ID);
          if (ownerWorkspace) {
            setIsLoading(true);
            updateCurrentUser(ownerWorkspace, () => {
              setIsLoading(false);
              handleGraphQlSuccess(res.acceptInvitation?.message);
              navigate(`${ROUTES.MAIN}${ownerWorkspace}`, { replace: true });
            });
          } else {
            navigate(ROUTES.MAIN, { replace: true });
          }
        },
        onError: () => {
          localStorage.removeItem(WORKSPACE_VERIFY_ID);
          navigate(ROUTES.MAIN, { replace: true });
        },
      });
    } else {
      navigate(ROUTES.MAIN, { replace: true });
    }
  }, []);

  if (loading || isLoading) {
    return (
      <ScreenLayout>
        <FormLoading />
      </ScreenLayout>
    );
  }
  return null;
}
