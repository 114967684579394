import {
  BellRinging,
  CalendarBlank,
  ChatCenteredDots,
  Envelope,
  Headset,
  Info,
  Lightbulb,
  Question,
  Robot,
  ShoppingCartSimple,
} from '@phosphor-icons/react';
import { REGEX } from '../../../../../common/constants';

export const iconOptionMapping: { [key: string]: React.ReactNode } = {
  'ph-headset': (
    <Headset color="var(--content-primary)" weight="fill" size={24} />
  ),
  'ph-chat-centered-dots': (
    <ChatCenteredDots color="var(--content-primary)" weight="fill" size={24} />
  ),
  'ph-question': (
    <Question color="var(--content-primary)" weight="fill" size={24} />
  ),
  'ph-robot': <Robot color="var(--content-primary)" weight="fill" size={24} />,
  'ph-envelope': (
    <Envelope color="var(--content-primary)" weight="fill" size={24} />
  ),
  'ph-shopping-cart-simple': (
    <ShoppingCartSimple
      color="var(--content-primary)"
      weight="fill"
      size={24}
    />
  ),
  'ph-bell-ringing': (
    <BellRinging color="var(--content-primary)" weight="fill" size={24} />
  ),
  'ph-calendar-blank': (
    <CalendarBlank color="var(--content-primary)" weight="fill" size={24} />
  ),
  'ph-info': <Info color="var(--content-primary)" weight="fill" size={24} />,
  'ph-lightbulb': (
    <Lightbulb color="var(--content-primary)" weight="fill" size={24} />
  ),
};

// Utility function to convert hex to RGB
const hexToRgb = (hex: string): { r: number; g: number; b: number } | null => {
  const result = REGEX.HEX.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

// utility function to determine text color based on background color
export const getTextColor = (backgroundColor: string): string => {
  const rgb = hexToRgb(backgroundColor);
  if (!rgb) return 'var(--content-primary)';

  const { r, g, b } = rgb;

  // calculate luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance > 0.5
    ? 'var(--content-primary)'
    : 'var(--content-inverse-primary)'; // return black for light colors, white for dark colors
};
