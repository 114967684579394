import { Flex } from 'antd';
import { lowerCase, map } from 'lodash';
import { useContext } from 'react';
import { AppContext } from '../../../../AppContext';
import {
  FormDesign,
  FormTheme,
  FormThemeMode,
  FormThemeVariation,
} from '../../../../__generated__/graphql';
import {
  AppActionType,
  AppContextType,
} from '../../../../types/appContext.type';
import { FormThemeType, Palette } from '../../../form/form.types';

type ThemeVariationsListType = {
  themeList?: FormTheme[];
  formThemeMode: FormThemeMode;
  value?: string;
  themeId?: string;
  onChange: (value: FormDesign) => void;
  disabled?: boolean;
};

export default function ThemeVariationsList({
  themeList,
  formThemeMode,
  value,
  themeId,
  onChange,
  disabled = false,
}: ThemeVariationsListType) {
  const {
    state: { systemThemeMode },
    dispatch,
  } = useContext(AppContext) as AppContextType;

  const renderThemeCard = (theme: FormThemeVariation) => {
    const mode =
      formThemeMode === FormThemeMode.Auto ? systemThemeMode : formThemeMode;

    const style: Palette = theme?.colorPalette?.[lowerCase(mode as string)];

    switch (theme?.type) {
      case FormThemeType.SOLID:
        return (
          <Flex
            className={`card ${disabled ? 'disabled' : ''} ${theme.id === value ? 'active' : ''}`}
            onClick={() => {
              if (disabled) return undefined;
              onChange({ themeVariationId: theme.id });
              dispatch({
                type: AppActionType.setActiveThemeIds,
                data: {
                  activeThemeVariationId: theme.id,
                },
              });
            }}
            vertical
            style={{
              background: style?.bgPrimary,
            }}
          >
            <Flex
              className="page-filled h-full"
              vertical
              gap={12}
              align="flex-start"
              justify="space-between"
            >
              <Flex gap={4} vertical align="flex-start">
                <div
                  className="mock-bar"
                  style={{
                    background: style?.textColor,
                  }}
                ></div>
                <div
                  className="mock-sub-bar"
                  style={{
                    background: style?.textColor,
                  }}
                ></div>
              </Flex>
              <div
                className="mock-button"
                style={{
                  borderColor: style?.textColor,
                  background: style?.bgPrimary,
                }}
              ></div>
            </Flex>
          </Flex>
        );
      case FormThemeType.GRADIENT:
        return (
          <Flex
            className={`card ${disabled ? 'disabled' : ''} ${theme.id === value ? 'active' : ''}`}
            onClick={() => {
              if (disabled) return undefined;
              onChange({ themeVariationId: theme.id });
              dispatch({
                type: AppActionType.setActiveThemeIds,
                data: {
                  activeThemeVariationId: theme.id,
                },
              });
            }}
            vertical
            style={{
              background: `linear-gradient(${style?.gradientDegree}deg, ${style?.bgPrimary} 1.27%, ${style?.bgSecondary} 70.38%)`,
            }}
          >
            <Flex
              className="page-filled h-full"
              vertical
              gap={12}
              align="flex-start"
              justify="space-between"
            >
              <Flex gap={4} vertical align="flex-start">
                <div
                  className="mock-bar"
                  style={{
                    background: style?.textColor,
                  }}
                ></div>
                <div
                  className="mock-sub-bar"
                  style={{
                    background: style?.textColor,
                  }}
                ></div>
              </Flex>
              <div
                className="mock-button"
                style={{
                  borderColor: style?.textColor,
                  background: style?.bgPrimary,
                }}
              ></div>
            </Flex>
          </Flex>
        );
      case FormThemeType.PATTERN:
        return (
          <Flex
            className={`card ${disabled ? 'disabled' : ''} ${theme.id === value ? 'active' : ''}`}
            onClick={() => {
              if (disabled) return undefined;
              onChange({ themeVariationId: theme.id });
              dispatch({
                type: AppActionType.setActiveThemeIds,
                data: {
                  activeThemeVariationId: theme.id,
                },
              });
            }}
            vertical
            style={{
              backgroundImage: `url('${style?.bgImage}')`,
              backgroundColor: style?.bgPrimary,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <Flex
              className="page-filled h-full"
              vertical
              gap={12}
              align="flex-start"
              justify="space-between"
            >
              <Flex gap={4} vertical align="flex-start">
                <div
                  className="mock-bar"
                  style={{
                    background: style?.textColor,
                  }}
                ></div>
                <div
                  className="mock-sub-bar"
                  style={{
                    background: style?.textColor,
                  }}
                ></div>
              </Flex>
              <div
                className="mock-button"
                style={{
                  borderColor: style?.textColor,
                  background: style?.bgPrimary,
                }}
              ></div>
            </Flex>
          </Flex>
        );

      default:
        break;
    }
  };
  const cardList = themeList?.filter((val) => val.id === themeId);
  return (
    <Flex gap={12} className="theme-variations-wrapper" wrap>
      {map(cardList?.[0]?.themeVariations, (theme) =>
        renderThemeCard(theme as FormThemeVariation),
      )}
    </Flex>
  );
}
