import { X } from '@phosphor-icons/react';
import { Flex, Input, Modal, ModalProps } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import React from 'react';
import { WorkspaceCustomDomain } from '../../__generated__/graphql';
import CommonButton from '../primitives/CommonButton';

const RemoveDomainModal: React.FC<
  ModalProps & {
    content: string;
    domainInfo: WorkspaceCustomDomain;
  }
> = ({
  open,
  onClose,
  onOk,
  title,
  content,
  footer,
  cancelButtonProps,
  okButtonProps,
  okText = 'Delete',
  domainInfo,
}) => {
  const defaultFooter = () => (
    <Flex justify="flex-end" gap={8}>
      <CommonButton
        key="cancel"
        type="default"
        onClick={(e) => {
          if (onClose) {
            onClose(e);
          }
        }}
        {...cancelButtonProps}
      >
        Cancel
      </CommonButton>
      <CommonButton key="submit" onClick={onOk} {...okButtonProps}>
        {okText}
      </CommonButton>
    </Flex>
  );

  return (
    <Modal
      title={
        <Flex align="center" justify="space-between" className="mb-16">
          <Paragraph className="mb-0 font-secondary text-lg semi-bold">
            {title || 'Delete Confirmation'}
          </Paragraph>
          <X
            size={24}
            color="var(--neutral-09)"
            className="cursor-pointer"
            onClick={(e) => {
              if (onClose) {
                onClose(e);
              }
            }}
          />
        </Flex>
      }
      open={open}
      centered
      closeIcon={null}
      onClose={onClose}
      footer={footer || defaultFooter}
      className="remove-domain-modal"
    >
      <Flex vertical gap={16}>
        <Paragraph className="mb-0 text-s medium text-content-secondary">
          {content}
        </Paragraph>
        {domainInfo?.customDomain && (
          <Input value={domainInfo?.customDomain} disabled></Input>
        )}
        <Flex vertical align="flex-start">
          <Paragraph className="mb-0 text-sm medium text-content-secondary">
            IMPORTANT:
          </Paragraph>
          <ul className="domain-remove-alert">
            <li>Links shared with this domain will stop working.</li>
            <li>
              Existing links will move back to default links and all working
              links will stop working.
            </li>
          </ul>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default RemoveDomainModal;
