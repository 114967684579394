import { X } from '@phosphor-icons/react';
import { Drawer, DrawerProps, Modal } from 'antd';
import { lowerCase } from 'lodash';
import React, { ReactElement, useState } from 'react';
import { FormShare } from '../../../../../__generated__/graphql';
import { DEFAULT_EMBED_BUTTON_TEXT } from '../../../../../common/constants';
import CommonButton from '../../../../../components/primitives/CommonButton';
import { ChildrenPropType } from '../../../../../types/common.type';
import {
  EmbedButtonConfig,
  EmbedConfigsType,
  EmbedTypes,
} from '../../../form.types';
import { getTextColor, iconOptionMapping } from './embed-utils';

export default function RenderWidget({
  children,
  widgetInfo,
}: ChildrenPropType & {
  widgetInfo: FormShare;
}) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenSlider, setIsOpenSlider] = useState(false);
  const [isOpenChat, setIsOpenChat] = useState(false);
  const [isOpenSideTab, setIsOpenSideTab] = useState(false);

  const embedConfigs: EmbedConfigsType = widgetInfo?.embedConfigs;
  const buttonConfig: EmbedButtonConfig | undefined = embedConfigs?.button;
  const buttonTextColor = buttonConfig?.backgroundColor
    ? getTextColor(buttonConfig?.backgroundColor)
    : 'var(--content-primary)';

  const PopupButton = () => (
    <CommonButton
      size="large"
      className="widget-button"
      onClick={() => {
        setIsOpenModal(true);
      }}
      style={{
        backgroundColor: buttonConfig?.backgroundColor,
        fontSize: buttonConfig?.fontSize,
        borderRadius: buttonConfig?.radius,
        color: buttonTextColor,
      }}
    >
      {buttonConfig?.text || DEFAULT_EMBED_BUTTON_TEXT}
    </CommonButton>
  );

  const SliderButton = () => (
    <CommonButton
      size="large"
      className="widget-button"
      style={{
        backgroundColor: buttonConfig?.backgroundColor,
        fontSize: buttonConfig?.fontSize,
        borderRadius: buttonConfig?.radius,
        color: buttonTextColor,
      }}
      onClick={() => {
        setIsOpenSlider(true);
      }}
    >
      {buttonConfig?.text || DEFAULT_EMBED_BUTTON_TEXT}
    </CommonButton>
  );

  const PopoverButton = () => (
    <CommonButton
      size="large"
      className="popover-button"
      shape="circle"
      onClick={() => {
        setIsOpenChat(!isOpenChat);
      }}
      icon={
        buttonConfig?.iconColor ? (
          !isOpenChat ? (
            React.cloneElement(
              iconOptionMapping?.[buttonConfig.icon] as ReactElement,
              {
                color: buttonConfig.iconColor,
              },
            )
          ) : (
            <X color={buttonConfig.iconColor} size={24} />
          )
        ) : null
      }
      style={{
        backgroundColor: buttonConfig?.backgroundColor,
      }}
    />
  );

  const renderWidget = () => {
    if (embedConfigs?.type) {
      switch (embedConfigs.type) {
        case EmbedTypes.POPUP:
          return (
            <div className="control-widget">
              <PopupButton />
            </div>
          );
        case EmbedTypes.SLIDER:
          return (
            <div className="control-widget">
              <SliderButton />
            </div>
          );
        case EmbedTypes.POPOVER:
          return (
            <div className="control-widget">
              {isOpenChat && (
                <div className="widget-popover-wrapper">{children}</div>
              )}
              <PopoverButton />
            </div>
          );
        case EmbedTypes.SIDE_TAB:
          return (
            <div className="control-widget">
              <div
                className={`widget-sidetab-wrapper ${isOpenSideTab ? 'open' : ''}`}
              >
                {children}
              </div>
              <CommonButton
                size="large"
                className={`sidetab-button ${isOpenSideTab ? 'open' : ''}`}
                iconPosition="end"
                icon={
                  buttonConfig?.iconColor ? (
                    !isOpenSideTab ? (
                      React.cloneElement(
                        iconOptionMapping?.[buttonConfig.icon] as ReactElement,
                        {
                          color: buttonConfig.iconColor,
                        },
                      )
                    ) : (
                      <X color={buttonConfig.iconColor} size={24} />
                    )
                  ) : null
                }
                onClick={() => {
                  setIsOpenSideTab(!isOpenSideTab);
                }}
                style={{
                  backgroundColor: buttonConfig?.backgroundColor,
                  color: buttonTextColor,
                  borderRadius: `${buttonConfig?.radius}px 0 0 ${buttonConfig?.radius}px`,
                  fontSize: buttonConfig?.fontSize,
                }}
              >
                <span className="btn-vertical-text">
                  {buttonConfig?.text || DEFAULT_EMBED_BUTTON_TEXT}
                </span>
              </CommonButton>
            </div>
          );
        default:
          return children;
      }
    } else {
      return children;
    }
  };

  const handleCancel = () => {
    setIsOpenModal(false);
  };

  const handleSliderClose = () => {
    setIsOpenSlider(false);
  };

  return (
    <>
      <div className="widget-controls-wrapper">{renderWidget()}</div>
      {isOpenModal && (
        <Modal
          className="widget-modal"
          open={isOpenModal}
          footer={null}
          onCancel={handleCancel}
          centered
          width={`calc(${embedConfigs?.popupSize}% - 80px)`}
          closeIcon={<X color="var(--neutrals)" size={24} />}
        >
          {children}
        </Modal>
      )}
      {embedConfigs?.position && (
        <Drawer
          className="widget-drawer"
          title={null}
          placement={
            lowerCase(embedConfigs?.position) as DrawerProps['placement']
          }
          onClose={handleSliderClose}
          open={isOpenSlider}
          width={800}
          closeIcon={<X color="var(--neutrals)" size={24} />}
        >
          {children}
        </Drawer>
      )}
    </>
  );
}
