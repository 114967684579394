import { Tooltip } from 'antd';
import CommonTag from '../../../../components/primitives/CommonTag';

export default function UserRoleLabel() {
  return (
    <Tooltip title="You can view this form but not edit it.  Need to make changes?  Reach out to the workspace owner and request editor access.">
      <CommonTag
        color="var(--surface-inverse-tertiary)"
        className="text-meta cursor-pointer"
      >
        View only
      </CommonTag>
    </Tooltip>
  );
}
