import { Flex } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import { WorkFlowSvg } from '../../assets/icons';

export default function WorkFlow() {
  return (
    <section className="editor-wrapper workflow-tab">
      <div className="workflow-section">
        <div className="workflow-section-content">
          <Flex gap={24} vertical align="center">
            <WorkFlowSvg />
            <Flex>
              <Title level={2} className="mb-0 font-secondary">
                Coming Soon
              </Title>
            </Flex>
            <Flex vertical gap={16} className="workflow-list-wrapper">
              <Flex vertical>
                <Title level={3} className="mb-0 font-secondary">
                  Streamline your workflow and maximize efficiency.
                </Title>
                <Paragraph className="mb-0 text-base text-content-secondary">
                  Form Flow puts you in control of your form's automation and
                  integrations.
                </Paragraph>
              </Flex>
              <ul className="workflow-list">
                <li>Never miss a submission with notification management</li>
                <li>Automate form availability with form scheduling</li>
                <li>
                  Boost productivity by integrating with your essential tools
                </li>
              </ul>
            </Flex>
          </Flex>
        </div>
      </div>
    </section>
  );
}
