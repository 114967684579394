import { gql } from '../../../__generated__';

export const CREATE_FORM = gql(`
mutation CreateForm($data: CreateFormInput!) {
  createForm(data: $data) {
    data {
      id
    }
  }
}`);

export const ADD_FORM_ELEMENTS = gql(`
mutation AddFormElements($where: FormUniqueInput!, $data: AddFormElementInput!) {
  addFormElements(where: $where, data: $data) {
    data {
      structure
    }
  }
}`);

export const GENERATE_FORM_STRUCTURE = gql(`
 mutation GenerateFormStructure($data: FormUniqueInput!) {
  generateFormStructure(data: $data) {
    data {
      id
      status
      isUpdated
      title
      previewKey
      structures {
        overview
        structure
      }
      designs {
        themeId
        themeMode
        themeVariationId
      }
      settings {
        tone
        finalPreview
        notifyOn
        notifyAtDay
        notifyAtSubmission
        closingMessage
      }
    }
  }
}`);

export const UPDATE_FORM_STRUCTURE = gql(`
mutation UpdateFormStructure($where: FormUniqueInput!, $data: UpdateFormStructureInput!) {
  updateFormStructure(where: $where, data: $data) {
    data {
      id
      status
      isUpdated
      previewKey
      structures {
        overview
        structure
      }
      designs {
        themeId
        themeMode
        themeVariationId
      }
      settings {
        tone
        finalPreview
        notifyOn
        notifyAtDay
        notifyAtSubmission
        closingMessage
      }
    }
  }
}`);

export const UPDATE_FORM_DESIGN = gql(`
mutation UpdateFormDesign($where: FormUniqueInput!, $data: UpdateFormDesignInput!) {
  updateFormDesign(where: $where, data: $data) {
    message
    data {
      previewKey
      status
      isUpdated
    }
  }
}`);

export const UPDATE_FORM_SETTING = gql(`
mutation UpdateFormSetting($where: FormUniqueInput!, $data: UpdateFormSettingInput!) {
  updateFormSetting(where: $where, data: $data) {
    message
    data {
      previewKey
      status
      isUpdated
    }
  }
}`);

export const PUBLISH_FORM = gql(`
 mutation PublishForm($where: FormUniqueInput!) {
    publishForm(where: $where) {
      message
      data {
        previewKey
        status
        isUpdated
      }
    }
}`);

export const UPDATE_FORM_TITLE = gql(`
  mutation UpdateForm($where: FormUniqueInput!, $data: UpdateFormInput!) {
    updateForm(where: $where, data: $data) {
      message
      data {
        title
      }
    }
}`);

export const UPDATE_FORM_SHARE = gql(`
  mutation UpdateFormShare($data: FormShareUpdateInput!, $where: FormShareUniqueInput!) {
  updateFormShare(data: $data, where: $where) {
    message
    data {
      id
    }
  }
}`);

export const UPDATE_FORM_TEMPLATE = gql(`
  mutation UpdateFormTemplate($where: FormUniqueInput!, $data: UpdateFormTemplateDataInput!) {
  updateFormTemplate(where: $where, data: $data) {
    message
  }
}`);

export const UNPUBLISH_FORM = gql(`
mutation UnpublishForm($where: FormUniqueInput!, $data: UnpublishFormData) {
  unpublishForm(where: $where, data: $data) {
    data {
      status
    }
    message
  }
}`);

export const USE_FORM_TEMPLATE = gql(`
mutation UseFormTemplate($data: UseTemplateUniqueSlugInput!) {
  useFormTemplate(data: $data) {
    data {
      id
    }
  }
}`);

export const UPDATE_DRAFT_FORM_RESPONSE = gql(`
 mutation UpdateDraftForm($where: FormUniqueInput!) {
  updateDraftForm(where: $where) {
    updated
  }
}`);

export const CREATE_FORM_SHARE = gql(`
 mutation CreateFormShare($data: FormShareCreationInput!, $where: FormUniqueInput!) {
  createFormShare(data: $data, where: $where) {
    message
    data {
      id
    }
  }
}`);

export const DELETE_FORM_SHARE = gql(`
 mutation DeleteFormShare($where: FormShareUniqueInput!) {
  deleteFormShare(where: $where) {
    message
  }
}`);
