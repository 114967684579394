import { useContext, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AppContext } from './AppContext';
import { ROUTES, TEMPLATE_ID, WORKSPACE_VERIFY_ID } from './common/constants';
import useRouter from './hooks/useRouter';
import { AppContextType } from './types/appContext.type';

const PrivateRoute = () => {
  const { getToken } = useContext(AppContext) as AppContextType;
  const idToken = getToken();
  const { params, location } = useRouter();
  const path = location.pathname;

  useEffect(() => {
    if (params?.slug && path?.includes(ROUTES.CREATE_FORM_SLUG)) {
      localStorage?.setItem(TEMPLATE_ID, params.slug);
    }
    if (params?.workspaceId && path?.includes(ROUTES.VERIFY_WORKSPACE_INVITE)) {
      localStorage?.setItem(WORKSPACE_VERIFY_ID, params.workspaceId);
    }
  }, [params]);

  return !idToken ? <Navigate to={ROUTES.LOGIN} /> : <Outlet />;
};
export default PrivateRoute;
