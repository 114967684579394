// import { gql } from '../../../__generated__';

import { gql } from '../../../__generated__';

export const GET_CURRENT_USER = gql(`
 query CurrentUser {
  currentUser {
    companyName
    email
    phoneNumber
    name
    roles
    profileImage
    isNewUser
    countryCode
    currentWorkspace {
      id
      uuid
      name
      storageUsed
      workspaceMembers {
        role
        id
      }
    }
    subscriptionPlan {
      features
    }
  }
}
`);

export const GET_USER_ROLE = gql(`
  query CurrentUserRole {
   currentUser {
    currentWorkspace {
       workspaceMembers {
         role
         id
       }
     }
   }
 }
 `);

export const CHECK_PHONE_NUMBER = gql(`
  query Query($data: IsPhoneNumberInUseInput!) {
  isPhoneNumberInUse(data: $data) {
    isPhoneNumberInUsed
  }
}`);
