import { useLazyQuery, useMutation } from '@apollo/client';
import {
  CaretDown,
  CaretLeft,
  Check,
  Clock,
  Gear,
} from '@phosphor-icons/react';
import {
  Alert,
  Flex,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  Select,
  Spin,
  Tooltip,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import Paragraph from 'antd/es/typography/Paragraph';
import { isEmpty, lowerCase, split, startCase } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import {
  DomainVerificationRecordStatus,
  FormShare,
  FormShareDomainType,
  FormShareUpdateInput,
  WorkspaceCustomDomain,
} from '../../../../__generated__/graphql';
import { AppContext } from '../../../../AppContext';
import {
  COMING_SOON_TEXT,
  DROPDOWN_LIST,
  ROUTES,
} from '../../../../common/constants';
import {
  formValidation,
  getFormLink,
  handleGraphQlSuccess,
  hasFeatureAccess,
} from '../../../../common/utils';
import SubscribeModal from '../../../../components/common/SubscribeModal';
import CommonButton from '../../../../components/primitives/CommonButton';
import CommonRadio from '../../../../components/primitives/CommonRadio';
import useFeatureAccess from '../../../../hooks/useFeatureAccess';
import useRouter from '../../../../hooks/useRouter';
import { AppContextType } from '../../../../types/appContext.type';
import { Permissions, ZinqSubDomain } from '../../../../types/common.type';
import { FeatureKeys } from '../../../profile/profile.types';
import { GET_DOMAIN_DETAILS } from '../../../workspace/graphql/queries';
import { UPDATE_FORM_SHARE } from '../../graphql/mutations';
type CustomizeDomainType = {
  details: FormShare;
  onBack: (step: number) => void;
};

export default function CustomizeDomain({
  details,
  onBack,
}: CustomizeDomainType) {
  const [selectedRadio, setSelectedRadio] = useState<FormShareDomainType>();
  const [domainInfo, setDomainInfo] = useState<WorkspaceCustomDomain>();
  const {
    state: { currentUser },
    hasPermission,
    getWorkspaceId,
  } = useContext(AppContext) as AppContextType;

  const { navigate, params } = useRouter();
  const shareId = params?.shareId as string;
  const editAccess = hasPermission(Permissions.WRITE);
  const [customDomainForm] = useForm();
  const [subdomainForm] = useForm();
  const [isDisabled, setIsDisabled] = useState(true);
  const [shareDetails, setShareDetails] = useState<FormShare>();

  const {
    checkFeatureAccess,
    isSubscriptionModalVisible,
    handleUpgradeSuccess,
    closeSubscriptionModal,
  } = useFeatureAccess();

  const [updateFormShareMutate, { loading: updateLoading }] = useMutation(
    UPDATE_FORM_SHARE,
    {
      fetchPolicy: 'network-only',
      onError: (err) => {
        customDomainForm.setFields([
          {
            name: 'slug',
            errors: [err?.message],
          },
        ]);
      },
      onCompleted: (res) => {
        handleGraphQlSuccess(res.updateFormShare?.message);
      },
    },
  );

  const [fetchDomains, { loading }] = useLazyQuery(GET_DOMAIN_DETAILS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res.domain?.data) {
        setDomainInfo(res.domain?.data);
        customDomainForm.setFields([
          { name: 'customDomain', value: res.domain.data.customDomain },
          {
            name: 'slug',
            value:
              split(shareDetails?.customLink, '/')?.[1] || shareDetails?.key,
          },
        ]);
      }
    },
    onError: () => {},
  });

  useEffect(() => {
    setShareDetails(details);
  }, [details]);

  useEffect(() => {
    fetchDomains();
  }, [shareId]);

  const hasCustomDomainAccess = hasFeatureAccess(
    currentUser?.subscriptionPlan?.features,
    FeatureKeys.CUSTOM_DOMAIN,
  );

  const handleUpdateFormShare = (payload: FormShareUpdateInput) => {
    updateFormShareMutate({
      variables: {
        where: {
          formShareId: shareId,
        },
        data: payload,
      },
    });
  };

  const handleRadioChange = ({ target: { value } }: RadioChangeEvent) => {
    setSelectedRadio(value);
    let payload: FormShareUpdateInput = {};
    if (value === FormShareDomainType.Default) {
      payload = {
        domainType: value,
      };
    } else if (value === FormShareDomainType.Subdomain) {
      payload = { domainType: value };
    } else if (value === FormShareDomainType.CustomDomain) {
      if (
        domainInfo?.customDomain &&
        domainInfo?.customDomainStatus == DomainVerificationRecordStatus.Success
      ) {
        // prepare custom link here
        const { customDomain, slug } = customDomainForm.getFieldsValue();
        const customLink = `${customDomain}/${slug}`;

        payload = {
          domainType: value,
          customLink,
        };
      }
    }

    if (!isEmpty(payload)) {
      handleUpdateFormShare(payload);
    }
  };

  const defaultLink = getFormLink(
    shareDetails?.key || '',
    {
      customLink: details!.customLink!,
      domainType: FormShareDomainType.Default,
    },
    shareDetails?.embedConfigs,
  );

  useEffect(() => {
    if (shareDetails) {
      setSelectedRadio(shareDetails.domainType!);
      subdomainForm.setFields([
        {
          name: 'slug',
          value: split(shareDetails?.customLink, '/')?.[1] || shareDetails?.key,
        },
        {
          name: 'domain',
          value: '.zinq.ai/',
        },
      ]);
    }
  }, [shareDetails, subdomainForm]);

  const redirectToWorkspace = () => {
    const workspaceId = getWorkspaceId();
    if (workspaceId) {
      navigate(`${ROUTES.WORKSPACE_SETTING}/${workspaceId}`);
    }
  };

  const handleCustomDomain = () => {
    checkFeatureAccess(FeatureKeys.CUSTOM_DOMAIN, () => {
      redirectToWorkspace();
    });
  };

  const handleCustomDomainFinish = () => {
    const { customDomain, slug } = customDomainForm.getFieldsValue();
    const customLink = `${customDomain}/${slug}`;
    subdomainForm.setFields([
      {
        name: 'slug',
        value: slug,
      },
    ]);

    setShareDetails({
      ...shareDetails,
      customLink,
      domainType: FormShareDomainType.CustomDomain,
    });

    setIsDisabled(true);
    handleUpdateFormShare({
      domainType: FormShareDomainType.CustomDomain,
      customLink,
    });
  };

  const handleCustomDomainFieldsChange = () => {
    const { slug } = customDomainForm.getFieldsValue();
    if (slug === split(shareDetails?.customLink, '/')?.[1]) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  };

  return (
    <div className="scroll-wrapper">
      <Spin spinning={loading || updateLoading}>
        <Flex
          gap={8}
          wrap
          align="center"
          justify="space-between"
          className="scroll-wrapper-header"
        >
          <Flex gap={12} align="center">
            <CommonButton
              type="text"
              onClick={() => onBack(0)}
              shape="circle"
              shadow={false}
              disabled={false}
              icon={<CaretLeft color="var(--content-primary)" size={20} />}
            />
            <Paragraph className="mb-0 text-m semi-bold">
              Customize Domain
            </Paragraph>
          </Flex>
        </Flex>
        <div className="scroll-wrapper-body">
          <Radio.Group
            onChange={handleRadioChange}
            value={selectedRadio}
            className="domain-selection-wrapper"
            disabled={!editAccess}
          >
            <CommonRadio boxType="primary" value={FormShareDomainType.Default}>
              <div>
                <Form
                  disabled={selectedRadio !== FormShareDomainType.Default}
                  autoComplete="off"
                >
                  <Flex vertical gap={8}>
                    <Flex vertical gap={2}>
                      <Paragraph className="mb-0 text-base text-content-primary medium">
                        Default URL
                      </Paragraph>
                      <Paragraph className="mb-0 text-sm text-content-tertiary">
                        Use ZINQ AI's default domain for your form links.
                      </Paragraph>
                    </Flex>
                    <Form.Item>
                      <Input
                        placeholder="Type here..."
                        disabled
                        value={defaultLink}
                      />
                    </Form.Item>
                  </Flex>
                </Form>
              </div>
            </CommonRadio>

            <CommonRadio
              boxType="primary"
              value={FormShareDomainType.CustomDomain}
            >
              <div>
                <Form
                  disabled={selectedRadio !== FormShareDomainType.CustomDomain}
                  form={customDomainForm}
                  onFinish={handleCustomDomainFinish}
                  autoComplete="off"
                  onFieldsChange={handleCustomDomainFieldsChange}
                >
                  <Flex vertical gap={16} align="flex-start">
                    <Flex
                      justify="space-between"
                      align="center"
                      className="w-full"
                      gap={8}
                    >
                      <Flex vertical gap={2}>
                        <Paragraph className="mb-0 text-base text-content-primary medium">
                          Custom domain
                        </Paragraph>
                        <Paragraph className="mb-0 text-sm text-content-tertiary">
                          Magnify your branding with a custom domain for your
                          forms.
                        </Paragraph>
                      </Flex>
                      {domainInfo?.customDomain && (
                        <CommonButton
                          type="text"
                          shape="circle"
                          icon={
                            <Gear
                              weight="fill"
                              size={20}
                              color="var(--content-primary)"
                            />
                          }
                          onClick={redirectToWorkspace}
                        />
                      )}
                    </Flex>
                    {domainInfo?.customDomain &&
                    domainInfo?.customDomainStatus ===
                      DomainVerificationRecordStatus.Success ? (
                      <Flex align="center" gap={8} className="w-full">
                        <div className="w-full">
                          <Form.Item
                            rules={[formValidation.required]}
                            name="customDomain"
                          >
                            <Input placeholder="Type here..." disabled />
                          </Form.Item>
                        </div>
                        <div className="w-full">
                          <Form.Item
                            name="slug"
                            rules={[
                              formValidation.required,
                              formValidation.slug,
                            ]}
                          >
                            <Input placeholder="form-name" />
                          </Form.Item>
                        </div>
                        <div>
                          <CommonButton
                            type="text"
                            shape="circle"
                            icon={
                              <Check size={20} color="var(--content-primary)" />
                            }
                            htmlType="submit"
                            disabled={isDisabled}
                          />
                        </div>
                      </Flex>
                    ) : (
                      <>
                        {domainInfo?.customDomainStatus ===
                        DomainVerificationRecordStatus.VerificationPending ? (
                          <Alert
                            message={startCase(
                              lowerCase(
                                DomainVerificationRecordStatus.VerificationPending,
                              ),
                            )}
                            type="warning"
                            showIcon
                            icon={
                              <Clock
                                weight="fill"
                                size={14}
                                color="var(--caution-on-surface)"
                              />
                            }
                            className="small"
                          />
                        ) : (
                          <CommonButton
                            type="text"
                            premium={!hasCustomDomainAccess}
                            onClick={handleCustomDomain}
                          >
                            Add Custom Domain
                          </CommonButton>
                        )}
                      </>
                    )}
                  </Flex>
                </Form>
              </div>
            </CommonRadio>

            <Tooltip title={COMING_SOON_TEXT}>
              <CommonRadio
                boxType="primary"
                value={FormShareDomainType.Subdomain}
                disabled
              >
                <div>
                  <Form
                    disabled={selectedRadio !== FormShareDomainType.Subdomain}
                    autoComplete="off"
                    form={subdomainForm}
                  >
                    <Flex vertical gap={8}>
                      <Flex vertical gap={2}>
                        <Paragraph className="mb-0 text-base text-content-primary medium">
                          Choose your ZINQ subdomain
                        </Paragraph>
                        <Paragraph className="mb-0 text-sm text-content-tertiary">
                          Create a personalized subdomain on ZINQ AI for your
                          forms.
                        </Paragraph>
                      </Flex>
                      <Flex align="center" gap={8} className="w-full">
                        <div className="w-full">
                          <Form.Item name="subDomain">
                            <Select
                              defaultValue={ZinqSubDomain.FORM}
                              options={DROPDOWN_LIST.subDomainList}
                              popupMatchSelectWidth={false}
                              suffixIcon={
                                <CaretDown
                                  size={20}
                                  color="var(--content-quarternary)"
                                />
                              }
                            />
                          </Form.Item>
                        </div>
                        <div className="w-full">
                          <Form.Item name="domain">
                            <Input placeholder="Type here..." disabled />
                          </Form.Item>
                        </div>
                        <div className="w-full">
                          <Form.Item name="slug">
                            <Input placeholder="form-name" />
                          </Form.Item>
                        </div>
                        {/* commenting for future use */}
                        {/* <div>
                          <CommonButton
                            type="text"
                            shape="circle"
                            icon={
                              <Check size={20} color="var(--content-primary)" />
                            }
                            htmlType="submit"
                          />
                        </div> */}
                      </Flex>
                    </Flex>
                  </Form>
                </div>
              </CommonRadio>
            </Tooltip>
          </Radio.Group>
        </div>
        {isSubscriptionModalVisible && (
          <SubscribeModal
            visible={isSubscriptionModalVisible}
            onCancel={closeSubscriptionModal}
            onUpgrade={handleUpgradeSuccess}
          />
        )}
      </Spin>
    </div>
  );
}
