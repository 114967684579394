import { useContext, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import useRouter from '../../hooks/useRouter';
import { AppContextType } from '../../types/appContext.type';

export default function DefaultWorkspace() {
  const {
    state: { currentUser },
    updateCurrentUser,
  } = useContext(AppContext) as AppContextType;
  const { navigate } = useRouter();

  useEffect(() => {
    const uuid = currentUser?.currentWorkspace?.uuid;
    if (uuid) {
      updateCurrentUser(uuid, () => {
        navigate(`${ROUTES.MAIN}${uuid}`);
      });
    }
  }, [currentUser]);
  return null;
}
