import type { TourProps } from 'antd';
import { Tour } from 'antd';
import React from 'react';
import { COMPANY_INFO } from '../../../common/constants';
import { setCookie } from '../../../common/utils';
import CustomIndicator from './CustomIndicator';

type PromptScreenTourProps = {
  refs: React.RefObject<HTMLElement>[];
  open: boolean;
  onOpenChange: (open: boolean) => void;
};

const PromptScreenTour: React.FC<PromptScreenTourProps> = ({
  refs,
  open,
  onOpenChange,
}) => {
  const handleClose = () => {
    onOpenChange(false);
    setCookie(
      'prompt-screen-tour',
      true,
      COMPANY_INFO.promptScreenTourCookieExpiry,
    );
  };
  const steps: TourProps['steps'] = [
    {
      title: '',
      description: `Tell us what information you want to collect, and our AI will build your form in seconds. It's that easy!`,
      target: () => refs[0].current as HTMLElement,
      onClose: handleClose,
    },
    {
      title: '',
      description: 'Here are some examples of prompts to generate a ZINQ form!',
      target: () => refs[1].current as HTMLElement,
      onClose: handleClose,
      onFinish: handleClose,
    },
  ];

  return (
    <Tour
      open={open}
      onClose={() => onOpenChange(false)}
      steps={steps}
      gap={{
        radius: 8,
      }}
      indicatorsRender={(current, total) => (
        <CustomIndicator current={current} total={total} />
      )}
      disabledInteraction
    />
  );
};

export default PromptScreenTour;
