import { CheckCircle, Clock, CopySimple, Warning } from '@phosphor-icons/react';
import { Alert, Flex, Modal, Skeleton, Table, Tooltip } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import { lowerCase, startCase } from 'lodash';
import React from 'react';
import {
  DomainVerificationRecord,
  DomainVerificationRecordStatus,
  WorkspaceCustomDomain,
} from '../../__generated__/graphql';
import { MODAL_SIZE } from '../../common/constants';
import { copyToClipboard } from '../../common/utils';
import CommonButton from '../primitives/CommonButton';

type DomainRecordsModalProps = {
  isVisible: boolean;
  onCancel: () => void;
  domainInfo: WorkspaceCustomDomain;
  loading: boolean;
  onVerifyClick: () => void;
};

const DomainRecordsModal: React.FC<DomainRecordsModalProps> = ({
  isVisible,
  onCancel,
  loading,
  onVerifyClick,
  domainInfo,
}) => {
  const handleCancel = () => {
    onCancel();
  };

  const renderVerificationStatus = (status: DomainVerificationRecordStatus) => {
    switch (status) {
      case DomainVerificationRecordStatus.Pending:
        return (
          <Alert
            message={startCase(lowerCase(status))}
            showIcon
            icon={
              <Clock
                weight="fill"
                size={16}
                color="var(--caution-on-surface)"
              />
            }
            type="warning"
            className="small"
          />
        );
      case DomainVerificationRecordStatus.Success:
        return (
          <Alert
            message={startCase(lowerCase(status))}
            showIcon
            icon={
              <CheckCircle
                weight="fill"
                size={16}
                color="var(--success-on-surface)"
              />
            }
            type="success"
            className="small"
          />
        );
      case DomainVerificationRecordStatus.Errored:
        return (
          <Alert
            message={startCase(lowerCase(status))}
            showIcon
            icon={
              <Warning
                weight="fill"
                size={16}
                color="var(--danger-on-surface)"
              />
            }
            type="error"
            className="small"
          />
        );
      default:
        return (
          <Alert message={startCase(lowerCase(status))} className="small" />
        );
    }
  };

  const ModalTitle = () => (
    <Title level={4} className="font-secondary text-content-primary">
      Domain records
    </Title>
  );

  const columns = [
    {
      title: 'Record type',
      dataIndex: 'type',
      key: 'type',
      render: (value: string) => (
        <Paragraph className="mb-0 text-base medium text-content-primary">
          {value}
        </Paragraph>
      ),
    },
    {
      title: 'Host',
      dataIndex: 'name',
      key: 'name',
      render: (value: string) => (
        <Paragraph className="mb-0 text-sm text-content-primary">
          {value}
        </Paragraph>
      ),
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: (text: string) => (
        <Flex gap={8} align="center">
          <Tooltip title={text}>
            <Paragraph className="mb-0 text-sm text-content-primary">
              {text.length > 20 ? `${text.substring(0, 20)}...` : text}
            </Paragraph>
          </Tooltip>
          <Tooltip title="Copy">
            <CopySimple
              size={20}
              color="var(--system-blue-dark)"
              className="cursor-pointer"
              onClick={() => {
                copyToClipboard(text);
              }}
            />
          </Tooltip>
        </Flex>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: DomainVerificationRecordStatus) =>
        loading ? (
          <Skeleton.Input
            active
            className="domain-verification-status-skeleton"
          />
        ) : (
          renderVerificationStatus(status)
        ),
    },
  ];

  const isVerifyDisabled = domainInfo?.customDomainVerificationRecords?.every(
    (record) => record?.status === DomainVerificationRecordStatus.Success,
  );

  return (
    <Modal
      title={<ModalTitle />}
      width={MODAL_SIZE.extraLarge}
      open={isVisible}
      onCancel={handleCancel}
      className="common-key-modal"
      footer={[
        <CommonButton key="cancel" onClick={handleCancel} disabled={loading}>
          Cancel
        </CommonButton>,
        <CommonButton
          key="confirm"
          type="primary"
          onClick={onVerifyClick}
          loading={loading}
          disabled={isVerifyDisabled}
        >
          {loading ? 'Verifying' : 'Verify'}
        </CommonButton>,
      ]}
    >
      <Table
        className="domain-record-table"
        dataSource={
          domainInfo?.customDomainVerificationRecords as DomainVerificationRecord[]
        }
        columns={columns}
        pagination={false}
        locale={{
          emptyText: 'No records found',
        }}
      />
    </Modal>
  );
};

export default DomainRecordsModal;
