import { gql } from '../../../__generated__';

export const PURCHASE_SUBSCRIPTION = gql(`
 mutation PurchaseSubscription($data: PurchaseSubscriptionInput!) {
  purchaseSubscription(data: $data) {
    data {
      transactionId
    }
    message
  }
}`);

export const CANCEL_SUBSCRIPTION = gql(`
  mutation CancelSubscriptionPlan($where: WorkspaceSubscriptionUniqueInput!) {
  cancelSubscriptionPlan(where: $where) {
    message
    success
  }
}`);
